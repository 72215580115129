import React from "react";
import logo from "./logo.svg";
import Subscription from "./components/SubscriptionContainer";
import Packages from "./components/Packages";
import "./App.css";
import { connect } from "react-redux";
// import {
//   postsubscriptions,
//   getsubscriptions,
//   getsubscriptionInfo
// } from "./redux/actions/dataActions";
import { storeid } from "./storeid";
import axios from "axios";



const mapStateToProps = (state) => ({
  UI: state.UI,
  data: state.data,
});

// const mapDispatchToProps = {
// getsubscriptionInfo,
// postsubscriptions,
// getsubscriptions,
// };

class App extends React.Component {
  state = {
    fetched: false,
  };
  componentWillMount(){

    let urlPath = window.location.pathname ;
        console.log(urlPath);
        var ar = urlPath.split('/');
        var storeId = ar[1];
        var token = ar[2]; 

      this.setState({
        fetched: true,
      });

    //   this.props.getsubscriptionInfo()
    //  this.props.getsubscriptions()
 
  }
  render() {
 
    return (
      <div className="container" style={{
        color: "#4e4f51"
      }}>
          <Packages></Packages>
      </div>
    );
  }
}

export default connect(
  mapStateToProps,
//   mapDispatchToProps
)(App);
