import React from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './Packages.css'
import ReactGA from "react-ga";
ReactGA.initialize("UA-157927849-1");
ReactGA.pageview(window.location.pathname + window.location.search);
const API_ROOT = "https://api2.intelikart.in";

class Packages extends React.Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            isInfoOpen: false,
            isThankyou: false,
            totalSubscriptionMonths: 0,
            subscriptionText: "",
            packageName: "BASIC",
            currentSubscribedPackageName: "",
            currentSubscribedID: 0,
            infoModalTitle: "",
            infoModalBody: "",

            couponCode: "",
            isCouponCodeValidate: true,
            isCouponCodeAvailable: true,
            couponAvailableMsg: "",
            couponLoading: false,
            discount: null,
            makePaymentLoading: false,
            isNewUser: false,

            isConfirmSubscriptionLoading: false,
            confirmSubscriptionDetails: {},
        };

        this.handleSubscriptionSelectionChange =
            this.handleSubscriptionSelectionChange.bind(this);
        this.openModal = this.openModal.bind(this);
        this.closeModalThankyou = this.closeModalThankyou.bind(this);
        this.goToPayment = this.goToPayment.bind(this);
        this.validateCoupon = this.validateCoupon.bind(this);
    }

    infoModalData = {
        "Website on Subdomain":
            "Free website on subdomain like https://yourbusiness.intelikart.com",
        "Web Store Manager":
            "Store manager accessible on desktop and from anywhere https://manage.intelikart.com",
        "Cash on Delivery": "Enable CoD for orders of your customers",
        "Mobile and Desktop Website":
            "Responsive websites look good on smartphones, tablets and laptops",
        "SEO Friendly Website":
            "SEO friendly websites to make your business listing appear on Google search results",
        "Premium Features":
            "Premium marketing platform, Invite only webinars & Dedicated customer success manager",
        "Android Mobile App":
            "Native Android mobile app live on google playstore",
        "Website on your domain":
            "Host your website on your domain like https://yourbusiness.com with unlimited hosting support. If you don't have domain, our customer support team will assist you to get one",
        "Payment Gateway":
            "Using online payment gateway, you can provide option to take payments online for orders of your customers. Please note that all online transaction attract around 2% payment gateway fee",
        "Banners": "Using Banners you can announce about deals and announcements",
        "Coupons": "Using Coupons you can offer discounts for your online sales",
        "Digital Visiting Card":
            "Digital visiting cards can be shared over social media and whatsapp",
        "QR Code for Store":
            "Your customers can scan QR Code to browse your website or to download your app from playstore",
        "Unlimited Products": "List unlimited products on your store",
        "Custom Themes":
            "Using custom themes, you can change the look and feel of your app and website",
        "Premium Marketing Platform":
            "Get capability for push-notification and announcements, email and sms marketing",
        "Invite-only Webinars":
            "In invite only webinars our marketing experts share tips and tricks to grow your business online",
        "Delivery Partners":
            "To deliver your products in other cities, you can enable delivery partners to handle your order deliveries",
        "Marketing Support":
            "Get personalised advice on marketing for your business from our marketing experts",
        "Premium Tech Support":
            "Prioritise support for new features and support",
        "Dedicated Account Manager":
            "Dedicated customer success manager for you",
        "Quick Orders":
            "Quickly receive orders as images of prescription or grocery list",
        "IK Academy":
            "Free video tutorials and blogs to learn and grow your business",
        "Bulk Product Upload":
            "Quickly add your products and categories using bulk upload in Web Store Manager",
        "Customers List": "See your customers list and engage with them",
        "Order Settings": "Minimum order, store timings and delivery range",
        "Taxes and Charges":
            "Customizable taxes like GST and charges like delivery and packaging charges",
        "SSL Certificate":
            "SSL certificates are used for securing your online store",
    };

    planPrice = {
        // plan_name : [3 months, 12 months, 24 months, 36 months]
        "BASIC" : [1999, 4999, 7999, 11999],
        "PRO" : [2999, 7999, 11999, 18999],
        "ADVANCE" : [3999, 11999, 18999, 24999],
    }

    openModal(pName) {
        this.setState({
            isOpen: true,
            packageName: pName,
            totalSubscriptionMonths: 0,
        });
    }

    closeModal = () =>
        this.setState({
            isOpen: false,
            couponCode: "",
            isCouponCodeValidate: true,
            isCouponCodeAvailable: true,
            couponAvailableMsg: "",
            couponLoading: false,
            makePaymentLoading: false,
            discount: null,
        });

    removeCoupon = () =>
        this.setState({
            couponCode: "",
            isCouponCodeValidate: true,
            isCouponCodeAvailable: true,
            couponAvailableMsg: "",
            couponLoading: false,
            makePaymentLoading: false,
            discount: null,
        });

    openInfoModal(info) {
        this.setState({
            infoModalTitle: info,
            infoModalBody: this.infoModalData[info],
            isInfoOpen: true,
        });
    }

    closeModalThankyou() {
        let [subdomain] = this.findIdAndToken();

        this.setState({
            isThankyou: false,
            confirmSubscriptionDetails: {},
            isConfirmSubscriptionLoading: false,
        });

        // read token from localStorage
        let token = this.getFromLocalStorage("token");
        window.location.href = `${window.location.origin}\\${subdomain}\\${token}`;

        // remove token, coupon from localStorage
        this.removeFromLocalStorage("token");
        this.removeFromLocalStorage("coupon");
        this.removeFromLocalStorage("subscription_id");
    }

    closeInfoModal = () => this.setState({ isInfoOpen: false });

    handleSubscriptionSelectionChange(e) {
        this.setState({ totalSubscriptionMonths: e.target.value });
    }

    findIdAndToken = () => {
        //alert('inside id and token');
        let urlPath = window.location.pathname;
        let ar = urlPath.split("/");
        let subdomain = ar[1];
        let token = ar[2];

        let fullSubdomain =  subdomain + ".intelikart.com";

        return [subdomain, token, fullSubdomain];
    };

    async goToPayment() {
        // create subscription and redirect to payments

        let [subdomain, token, fullSubdomain] = this.findIdAndToken();

        let subscriptionPrice = 0;

        let mapMonthArray = {
            "3" : 0,
            "12" : 1,
            "24" : 2,
            "36" : 3,
        }

        let originalPrice = parseInt(
            this.planPrice[this.state.packageName][
                mapMonthArray[this.state.totalSubscriptionMonths]
            ]
        );

        if (this.state.discount && this.state.discount > 0)
            subscriptionPrice = Math.round(
                originalPrice - (originalPrice * this.state.discount) / 100
            );
        else subscriptionPrice = originalPrice;

        // console.log(subscriptionPrice, this.state.totalSubscriptionMonths)

        const data = {
            subscription_time_months: this.state.totalSubscriptionMonths,
            subscription_price: subscriptionPrice,
            subscription_plan_name: this.state.packageName,
        };

        try {
            this.setState({
                makePaymentLoading: true,
            });

            const res = await axios.post(
                `${API_ROOT}/subscriptions/create`,
                data,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "x-ik-domain": `${fullSubdomain}`,
                    },
                }
            );

            // console.log("create subscription: ", res);
            // save coupon code and token
            this.saveToLocalStorage("token", token);
            let coupon =
                this.state.couponCode && this.state.discount > 0
                    ? this.state.couponCode
                    : null;
            this.saveToLocalStorage("coupon", coupon);
            this.saveToLocalStorage("subscription_id", res.data["id"]);

            let redirectUrl = `${window.location.origin}\\${subdomain}?confirmSub=1`;

            // redirect to phone pe payment link

            const req = {
                "ik-payment-token": res.data["ik-payment-token"],
                "redirect-url": redirectUrl,
            };

            const paymentRes = await axios.post(
                `${API_ROOT}/subscriptions/pay`,
                req,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "x-ik-domain": `${fullSubdomain}`,
                    },
                }
            );

            // console.log("payment: ", paymentRes);
            let phonepe_url = paymentRes.data.url;

            this.closeModal();

            window.location.href = phonepe_url;
        } catch (err) {
            console.log(err);
            this.setState({
                makePaymentLoading: false,
            });
        }
    }

    saveToLocalStorage = (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    };

    getFromLocalStorage = (key) => {
        const itemStr = localStorage.getItem(key);
        if (!itemStr) {
            return null;
        }
        const item = JSON.parse(itemStr);
        return item;
    };

    removeFromLocalStorage = (key) => {
        localStorage.removeItem(key);
    };

    componentDidMount() {
        let [_, _t, fullSubdomain] = this.findIdAndToken();

        axios
            .get(`${API_ROOT}/subscriptions/info`, {
                headers: {
                    "x-ik-domain": `${fullSubdomain}`,
                },
            })
            .then((res) => {
                this.setState({
                    isOpen: false,
                    subscriptionText: res.data.subscriptionText,
                    currentSubscribedPackageName:
                        res.data.currentSubscriptionName,
                    currentSubscribedID: res.data.subscriptionID,
                    isNewUser: res.data.isNewUser,
                });
            })
            .catch((err) => {
                console.log(err);
            });

        // check if thankyou page
        const queryString = window.location.search;
        // console.log("query: ", window.location.search);
        if (queryString.includes("confirmSub=1")) {
            this.setState({
                isThankyou: true,
                isConfirmSubscriptionLoading: true,
            });

            // read coupon and token from localStorage
            let token = this.getFromLocalStorage("token");
            let coupon = this.getFromLocalStorage("coupon");
            let subscription_id = this.getFromLocalStorage("subscription_id");

            this.confirmSubscription(coupon, fullSubdomain, token, subscription_id);

            this.triggerGoogleEvent();
            this.triggerTapfiliateEvent();
        }
    }

    confirmSubscription = async (
        couponCode,
        subdomain,
        token,
        subscription_id
    ) => {
        try {
            const response = await axios.get(
                `${API_ROOT}/subscriptions/confirm?subscription_id=${subscription_id}&coupon=${couponCode}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                        "x-ik-domain": `${subdomain}`,
                    },
                }
            );
            // console.log("confirm: ", response.data.data);

            this.setState({
                confirmSubscriptionDetails: response.data,
                isConfirmSubscriptionLoading: false,
            });
        } catch (err) {
            this.setState({
                isConfirmSubscriptionLoading: false,
            });
            console.log(err);
        }
    };

    triggerGoogleEvent() {
        ReactGA.event({
            category: "Subscriptions",
            action: "User Purchased Subscription",
            label: "subscription_purchased_thankyou",
        });
    }

    triggerTapfiliateEvent() {
        // todo : integrate tapfiliate tracking
    }

    async validateCoupon(e) {
        e.preventDefault();

        let couponCode = this.state.couponCode;
        let result = true;
        this.setState({
            isCouponCodeValidate: true,
            isCouponCodeAvailable: true,
        });
        if (/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(couponCode)) {
            this.setState({
                isCouponCodeValidate: false,
                couponAvailableMsg: "",
                discount: null,
            });
            result = false;
        } else if (result) {
            this.setState({ isCouponCodeValidate: true, couponLoading: true });
            try {
                const response = await axios.get(
                    `${API_ROOT}/subscriptions/coupons/${couponCode}`
                );
                const res = response.data;

                if (!res.valid) {
                    this.setState({
                        isCouponCodeAvailable: false,
                        couponLoading: false,
                        couponAvailableMsg: res.message,
                        discount: null,
                    });

                    result = false;
                } else {
                    this.setState({
                        couponAvailableMsg: res.message,
                        couponLoading: false,
                        discount: res.value,
                    });
                }
            } catch (err) {
                this.setState({
                    couponLoading: false,
                    couponAvailableMsg: "",
                    discount: null,
                });
                console.log(err);
            }
        }
        return result;
    }

    render() {
        return (
            <>
                <section id="contact" className="contact">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="info-box">
                                            <h3 className="">
                                                {this.state.subscriptionText}
                                            </h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="pricing" className="pricing">
                    <div className="container">
                        <div className="section-title"></div>

                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="box">
                                    {this.state.currentSubscribedPackageName ==
                                    "BASIC" ? (
                                        <span className="advanced">Active</span>
                                    ) : (
                                        ""
                                    )}
                                    <h3>BASIC</h3>
                                    <p className="text-primary font-weight-bold">
                                        Top Domain Website
                                    </p>
                                    <h4>
                                        <sup>₹</sup>
                                        {this.planPrice["BASIC"][0]}
                                        <span> / quarterly</span>
                                    </h4>
                                    <ul>
                                        <li>Everything in FREE </li>
                                        <li>
                                            Website on your domain{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Website on your domain"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Payment Gateway{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Payment Gateway"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Banners{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Banners"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Coupons{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Coupons"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Digital Visiting Card{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Digital Visiting Card"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            QR Code for Store{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "QR Code for Store"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Unlimited Products{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Unlimited Products"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li className="na">
                                            Android Mobile App{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Android Mobile App"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="btn-wrap">
                                        <a
                                            className="btn-buy"
                                            onClick={() =>
                                                this.openModal("BASIC")
                                            }
                                        >
                                            Buy Now
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 mt-4 mt-md-0">
                                <div className="box">
                                    {this.state.currentSubscribedPackageName ==
                                    "PRO" ? (
                                        <span className="advanced">Active</span>
                                    ) : (
                                        ""
                                    )}
                                    <h3>PRO</h3>
                                    <p className="text-primary font-weight-bold">
                                        Native Android Application
                                    </p>
                                    <h4>
                                        <sup>₹</sup>
                                        {this.planPrice["PRO"][0]}
                                        <span> / quarterly</span>
                                    </h4>
                                    <ul>
                                        <li>Everything in FREE </li>
                                        <li>
                                            Android Mobile App{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Android Mobile App"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Payment Gateway{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Payment Gateway"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Banners{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Banners"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Coupons{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Coupons"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            QR Code for Store{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "QR Code for Store"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Unlimited Products{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Unlimited Products"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Custom Themes{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Custom Themes"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li className="na">
                                            Website on your domain{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Website on your domain"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="btn-wrap">
                                        <a
                                            className="btn-buy"
                                            onClick={() =>
                                                this.openModal("PRO")
                                            }
                                        >
                                            Buy Now
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
                                <div className="box">
                                    {this.state.currentSubscribedPackageName ==
                                    "ADVANCE" ? (
                                        <span className="advanced">Active</span>
                                    ) : (
                                        ""
                                    )}
                                    <h3>ADVANCE</h3>
                                    <p className="text-primary font-weight-bold">
                                        Website + Android App
                                    </p>
                                    <h4>
                                        <sup>₹</sup>
                                        {this.planPrice["ADVANCE"][0]}
                                        <span> / quarterly</span>
                                    </h4>
                                    <ul>
                                        <li>Everything in BASIC + PRO </li>
                                        <li>
                                            Premium Features{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Premium Features"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Premium Marketing Platform{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Premium Marketing Platform"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Invite-only Webinars{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Invite-only Webinars"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Delivery Partners{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Delivery Partners"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Marketing Support{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Marketing Support"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Premium Tech Support{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Premium Tech Support"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Dedicated Account Manager{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Dedicated Account Manager"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="btn-wrap">
                                        <a
                                            className="btn-buy"
                                            onClick={() =>
                                                this.openModal("ADVANCE")
                                            }
                                        >
                                            Buy Now
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6 mt-4 mt-lg-0">
                                <div className="box">
                                    {this.state.currentSubscribedPackageName ==
                                    "FREE" ? (
                                        <span className="advanced">Active</span>
                                    ) : (
                                        ""
                                    )}

                                    <h3>FREE</h3>
                                    <p className="text-primary font-weight-bold">
                                        Subdomain Website
                                    </p>
                                    <h4>
                                        <sup>₹</sup>0<span> / forever</span>
                                    </h4>
                                    <ul>
                                        <li>
                                            Website on Subdomain{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Website on Subdomain"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Web Store Manager{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Web Store Manager"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Cash on Delivery{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Cash on Delivery"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Mobile and Desktop Website{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Mobile and Desktop Website"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            SEO Friendly Website{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "SEO Friendly Website"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Quick Orders{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Quick Orders"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Customers List{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Customers List"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            IK Academy{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "IK Academy"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            Taxes and Charges{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Taxes and Charges"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li>
                                            SSL Certificate{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "SSL Certificate"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>

                                        <li className="na">
                                            Premium Features{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Premium Features"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                        <li className="na">
                                            Android Mobile App{" "}
                                            <span
                                                onClick={() =>
                                                    this.openInfoModal(
                                                        "Android Mobile App"
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    icon={faInfoCircle}
                                                />
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal
                    show={this.state.isOpen}
                    onHide={this.closeModal}
                    backdrop={
                        this.state.makePaymentLoading ||
                        this.state.couponLoading
                            ? "static"
                            : true
                    }
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="text-center">
                        <Modal.Title
                            style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                color: "#007bff",
                            }}
                        >
                            {this.state.packageName} Plan{" "}
                            <span className="month-price">
                                (₹ {this.planPrice[this.state.packageName][0]} /
                                quarterly)
                            </span>
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="col-md-12">
                            {this.state.couponLoading ? (
                                <div className="row">
                                    <div className="col-12 d-flex justify-content-center">
                                        <div
                                            className="spinner-border text-primary"
                                            role="status"
                                        >
                                            <span className="sr-only">
                                                Loading...
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <>
                                <p><strong>Select the duration of your subscription: </strong></p>

                                    <div className="row">
                                        <div className="col-12">
                                            <input
                                                type="radio"
                                                name="months"
                                                value="36"
                                                group="paymentOption"
                                                onChange={
                                                    this
                                                        .handleSubscriptionSelectionChange
                                                }
                                                className="duration-input"
                                                id="36months"
                                                checked={
                                                    this.state
                                                        .totalSubscriptionMonths ===
                                                    "36"
                                                        ? true
                                                        : false
                                                }
                                            />
                                            <label
                                                htmlFor="36months"
                                                className="duration-box"
                                            >
                                                <div className="row">
                                                    <div className="col-6">
                                                        <span className="duration">
                                                            36 months
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        {this.state.discount ? (
                                                            <>
                                                                <strike>
                                                                    <span className="originalPrice">
                                                                        ₹{" "}
                                                                        {parseInt(
                                                                            this
                                                                                .planPrice[
                                                                                this
                                                                                    .state
                                                                                    .packageName
                                                                            ][3]
                                                                        )}
                                                                    </span>
                                                                </strike>
                                                                <span className="discountedPrice">
                                                                    {" "}
                                                                    ₹{" "}
                                                                    {Math.round(
                                                                        parseInt(
                                                                            this
                                                                                .planPrice[
                                                                                this
                                                                                    .state
                                                                                    .packageName
                                                                            ][3]
                                                                        ) -
                                                                            (parseInt(
                                                                                this
                                                                                    .planPrice[
                                                                                    this
                                                                                        .state
                                                                                        .packageName
                                                                                ][3]
                                                                            ) *
                                                                                this
                                                                                    .state
                                                                                    .discount) /
                                                                                100
                                                                    )}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <span className="discountedPrice">
                                                                ₹{" "}
                                                                {parseInt(
                                                                    this
                                                                        .planPrice[
                                                                        this
                                                                            .state
                                                                            .packageName
                                                                    ][3]
                                                                )}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <input
                                                type="radio"
                                                name="months"
                                                value="24"
                                                group="paymentOption"
                                                onChange={
                                                    this
                                                        .handleSubscriptionSelectionChange
                                                }
                                                className="duration-input"
                                                id="24months"
                                                checked={
                                                    this.state
                                                        .totalSubscriptionMonths ===
                                                    "24"
                                                }
                                            />
                                            <label
                                                htmlFor="24months"
                                                className="duration-box"
                                            >
                                                <div className="row">
                                                    <div className="col-6">
                                                        <span className="duration">
                                                            24 months
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        {this.state.discount ? (
                                                            <>
                                                                <strike>
                                                                    <span className="originalPrice">
                                                                        ₹{" "}
                                                                        {parseInt(
                                                                            this
                                                                                .planPrice[
                                                                                this
                                                                                    .state
                                                                                    .packageName
                                                                            ][2]
                                                                        )}
                                                                    </span>
                                                                </strike>
                                                                <span className="discountedPrice">
                                                                    {" "}
                                                                    ₹{" "}
                                                                    {Math.round(
                                                                        parseInt(
                                                                            this
                                                                                .planPrice[
                                                                                this
                                                                                    .state
                                                                                    .packageName
                                                                            ][2]
                                                                        ) -
                                                                            (parseInt(
                                                                                this
                                                                                    .planPrice[
                                                                                    this
                                                                                        .state
                                                                                        .packageName
                                                                                ][2]
                                                                            ) *
                                                                                this
                                                                                    .state
                                                                                    .discount) /
                                                                                100
                                                                    )}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <span className="discountedPrice">
                                                                ₹{" "}
                                                                {parseInt(
                                                                    this
                                                                        .planPrice[
                                                                        this
                                                                            .state
                                                                            .packageName
                                                                    ][2]
                                                                )}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <input
                                                type="radio"
                                                name="months"
                                                value="12"
                                                group="paymentOption"
                                                onChange={
                                                    this
                                                        .handleSubscriptionSelectionChange
                                                }
                                                className="duration-input"
                                                id="12months"
                                                checked={
                                                    this.state
                                                        .totalSubscriptionMonths ===
                                                    "12"
                                                }
                                            />
                                            <label
                                                htmlFor="12months"
                                                className="duration-box"
                                            >
                                                <div className="row">
                                                    <div className="col-6">
                                                        <span className="duration">
                                                            12 months
                                                        </span>
                                                    </div>
                                                    <div className="col-6 text-right">
                                                        {this.state.discount ? (
                                                            <>
                                                                <strike>
                                                                    <span className="originalPrice">
                                                                        ₹{" "}
                                                                        {parseInt(
                                                                            this
                                                                                .planPrice[
                                                                                this
                                                                                    .state
                                                                                    .packageName
                                                                            ][1]
                                                                        )}
                                                                    </span>
                                                                </strike>
                                                                <span className="discountedPrice">
                                                                    {" "}
                                                                    ₹{" "}
                                                                    {Math.round(
                                                                        parseInt(
                                                                            this
                                                                                .planPrice[
                                                                                this
                                                                                    .state
                                                                                    .packageName
                                                                            ][1]
                                                                        ) -
                                                                            (parseInt(
                                                                                this
                                                                                    .planPrice[
                                                                                    this
                                                                                        .state
                                                                                        .packageName
                                                                                ][1]
                                                                            ) *
                                                                                this
                                                                                    .state
                                                                                    .discount) /
                                                                                100
                                                                    )}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <span className="discountedPrice">
                                                                ₹{" "}
                                                                {parseInt(
                                                                    this
                                                                        .planPrice[
                                                                        this
                                                                            .state
                                                                            .packageName
                                                                    ][1]
                                                                )}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    {this.state.isNewUser && (
                                        <div className="row">
                                            <div className="col-12">
                                                <input
                                                    type="radio"
                                                    name="months"
                                                    value="3"
                                                    group="paymentOption"
                                                    onChange={
                                                        this
                                                            .handleSubscriptionSelectionChange
                                                    }
                                                    className="duration-input"
                                                    id="3months"
                                                    checked={
                                                        this.state
                                                            .totalSubscriptionMonths ===
                                                        "3"
                                                    }
                                                />
                                                <label
                                                    htmlFor="3months"
                                                    className="duration-box"
                                                >
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <span className="duration">
                                                                3 months
                                                            </span>
                                                        </div>
                                                        <div className="col-6 text-right">
                                                            {this.state
                                                                .discount ? (
                                                                <>
                                                                    <strike>
                                                                        <span className="originalPrice">
                                                                            ₹{" "}
                                                                            {parseInt(
                                                                                this
                                                                                    .planPrice[
                                                                                    this
                                                                                        .state
                                                                                        .packageName
                                                                                ][0]
                                                                            )}
                                                                        </span>
                                                                    </strike>
                                                                    <span className="discountedPrice">
                                                                        {" "}
                                                                        ₹{" "}
                                                                        {Math.round(
                                                                            parseInt(
                                                                                this
                                                                                    .planPrice[
                                                                                    this
                                                                                        .state
                                                                                        .packageName
                                                                                ][0]
                                                                            ) -
                                                                                (parseInt(
                                                                                    this
                                                                                        .planPrice[
                                                                                        this
                                                                                            .state
                                                                                            .packageName
                                                                                    ][0]
                                                                                ) *
                                                                                    this
                                                                                        .state
                                                                                        .discount) /
                                                                                    100
                                                                        )}
                                                                    </span>
                                                                </>
                                                            ) : (
                                                                <span className="discountedPrice">
                                                                    ₹{" "}
                                                                    {parseInt(
                                                                        this
                                                                            .planPrice[
                                                                            this
                                                                                .state
                                                                                .packageName
                                                                        ][0]
                                                                    )}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    )}
                                </>
                            )}

                            {this.state.discount ? (
                                <div className="row my-3 align-items-center">
                                    <div className="col-12">
                                        <p className="text-success coupon-success-msg">
                                            Coupon code applied successfully
                                        </p>
                                        <div className="coupon-box">
                                            <div className="row">
                                                <div className="col-8">
                                                    <h4>
                                                        {this.state.couponCode}
                                                    </h4>
                                                    <p className="mb-0">
                                                        ( {this.state.discount}{" "}
                                                        % off )
                                                    </p>
                                                </div>
                                                <div className="col-4 d-flex align-items-center justify-content-end">
                                                    <span
                                                        role="button"
                                                        className="btn btn-link text-danger btn-sm"
                                                        onClick={
                                                            this.removeCoupon
                                                        }
                                                    >
                                                        Remove
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div className="row my-3 align-items-center">
                                    <div className="col-12">
                                        <label htmlFor="couponCode">
                                            <strong>
                                                Have a coupon code:{" "}
                                            </strong>
                                        </label>
                                    </div>
                                    <div className="col-12">
                                        <div className="input-group mb-2">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Your Coupon code"
                                                value={this.state.couponCode}
                                                name="couponCode"
                                                id="couponCode"
                                                onChange={(e) => {
                                                    this.setState({
                                                        couponCode:
                                                            e.target.value?.toUpperCase(),
                                                    });
                                                }}
                                            />
                                            <div className="input-group-append">
                                                <button
                                                    className="btn btn-outline-primary"
                                                    type="button"
                                                    onClick={
                                                        this.validateCoupon
                                                    }
                                                    disabled={
                                                        !this.state.couponCode
                                                    }
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div
                                            className={`text-danger ${
                                                this.state
                                                    .isCouponCodeValidate ===
                                                    true &&
                                                this.state
                                                    .isCouponCodeAvailable ===
                                                    true
                                                    ? "d-none"
                                                    : ""
                                            }`}
                                        >
                                            <h5
                                                style={{
                                                    fontSize: 12 + "px",
                                                    marginTop: 0 + "px",
                                                    marginBottom: "2px",
                                                }}
                                                className={`${
                                                    this.state
                                                        .isCouponCodeValidate
                                                        ? "d-none"
                                                        : ""
                                                }`}
                                            >
                                                Coupon Code can't contain
                                                special characters.
                                            </h5>
                                            <h5
                                                style={{
                                                    fontSize: 12 + "px",
                                                    marginTop: 0 + "px",
                                                    marginBottom: "2px",
                                                }}
                                                className={`${
                                                    this.state
                                                        .isCouponCodeAvailable
                                                        ? "d-none"
                                                        : ""
                                                }`}
                                            >
                                                {this.state.couponAvailableMsg}
                                            </h5>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.totalSubscriptionMonths != 0 ? (
                            <div className="btn-wrap-pay">
                                <button
                                    className="btn btn-buy-pay"
                                    onClick={this.goToPayment}
                                    disabled={
                                        !!this.state.couponLoading ||
                                        !!this.state.makePaymentLoading
                                    }
                                >
                                    {this.state.makePaymentLoading ? (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        "Make Payment Now"
                                    )}
                                </button>
                            </div>
                        ) : (
                            <div className="btn-wrap-pay">
                                <button className="btn btn-buy-pay">
                                    Select your plan
                                </button>
                            </div>
                        )}
                    </Modal.Footer>
                </Modal>

                <Modal
                    show={this.state.isThankyou}
                    onHide={this.closeModalThankyou}
                    backdrop={
                        this.state.isConfirmSubscriptionLoading
                            ? "static"
                            : true
                    }
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    {this.state.isConfirmSubscriptionLoading ? (
                        <Modal.Body>
                            <div className="row">
                                <div className="col-12 d-flex justify-content-center">
                                    <div
                                        className="spinner-border text-primary"
                                        role="status"
                                    >
                                        <span className="sr-only">
                                            Loading...
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    ) : (
                        <>
                            <Modal.Header className="text-center">
                                <Modal.Title
                                    style={{
                                        fontSize: "16px",
                                        fontWeight: "bold",
                                        color: "#4e4f51",
                                    }}
                                >
                                    {this.state.confirmSubscriptionDetails
                                        .status == "success"
                                        ? "Thank You"
                                        : "Error"}
                                </Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                                <div className="col-md-12">
                                    {this.state.confirmSubscriptionDetails
                                        .status == "success" ? (
                                        <>
                                            <div className="row">
                                                <div className="col-12">
                                                    <p>
                                                        Thank you for
                                                        subscribing.
                                                        <br />
                                                        We wish growth for your
                                                        business.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="table-responsive">
                                                        <table className="table borderless">
                                                            <tbody>
                                                                <tr>
                                                                    <th>
                                                                        Subscription
                                                                        Plan
                                                                    </th>
                                                                    <td>
                                                                        {
                                                                            this
                                                                                .state
                                                                                .confirmSubscriptionDetails
                                                                                .subscription_plan_name
                                                                        }
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Renewal
                                                                        Date
                                                                    </th>
                                                                    <td>
                                                                        {this
                                                                            .state
                                                                            .confirmSubscriptionDetails
                                                                            .subscription_end_date
                                                                            ? new Date(
                                                                                  this.state.confirmSubscriptionDetails.subscription_end_date
                                                                              ).toDateString()
                                                                            : null}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="row">
                                            <div className="col-12">
                                                <p>
                                                    Payment Status:{" "}
                                                    {this.state.confirmSubscriptionDetails?.status?.toUpperCase()}
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="btn-wrap-pay">
                                    <button
                                        className="btn btn-buy-pay"
                                        onClick={this.closeModalThankyou}
                                        disabled={
                                            !!this.state
                                                .isConfirmSubscriptionLoading
                                        }
                                    >
                                        OK
                                    </button>
                                </div>
                            </Modal.Footer>
                        </>
                    )}
                </Modal>

                <Modal
                    show={this.state.isInfoOpen}
                    onHide={this.closeInfoModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header className="text-center">
                        <Modal.Title
                            style={{
                                fontSize: "16px",
                                fontWeight: "bold",
                                color: "#4e4f51",
                            }}
                        >
                            {this.state.infoModalTitle}
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="col-md-12">
                            {this.state.infoModalBody}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="btn-wrap-pay">
                            <button
                                className="btn btn-buy-pay"
                                onClick={this.closeInfoModal}
                            >
                                Close
                            </button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}

export default Packages;
