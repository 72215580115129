import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import dataReducer from "./reducers/dataReducer";
import uiReducer from "./reducers/uiReducer";

const initalstate = {};

const middleware = [thunk];

const reducers = combineReducers({
  data: dataReducer,
  UI: uiReducer,
});

const store = createStore(
  reducers,
  initalstate,
  compose(applyMiddleware(...middleware))
);

export default store;
