import React from "react";
import SubscriptionList from "./SubscriptionList"

class Subscription extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div
          className="row mt-3 d-block"
          style={{
            width: "90%",
            padding: "5px",
           }}
        >
          <SubscriptionList/>

        </div>

        <br></br>
      </React.Fragment>
    );
  }
}

export default Subscription;
