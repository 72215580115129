import React from "react";
import axios from "axios";
import { Button, Modal } from 'react-bootstrap';

class SubscriptionList extends React.Component {
  constructor() {
    super();
    this.state = {
      featureList: [],
      selected:[],
      isOpen: false,
      totalPerMonthPrice: 0,
      totalSubscriptionMonths: 0,
      subscriptionText: ''
    };
    this.handleFeatureSelectionChange = this.handleFeatureSelectionChange.bind(this);
    this.handleSubscriptionSelectionChange = this.handleSubscriptionSelectionChange.bind(this);
    this.alreadyChecked = this.alreadyChecked.bind(this);
    this.updatePackages = this.updatePackages.bind(this);
    this.calculatePerMonthPrices = this.calculatePerMonthPrices.bind(this);
    this.openModal = this.openModal.bind(this);
    this.goToPayment = this.goToPayment.bind(this);
  }

  openModal() {
    if(this.state.totalPerMonthPrice == 0){ return;}
    this.setState({ isOpen: true });
  }
  
  
  closeModal = () => this.setState({ isOpen: false });

  handleFeatureSelectionChange(event) {
    const target = event.target;
    var value = target.value;
    
    if (target.checked) {
      this.state.selected.push(value);
    } else {

      var index = this.state.selected.indexOf(value);
      if (index !== -1) { 
        this.state.selected.splice(index, 1);
      }
    }

    console.log(this.state);
    this.calculatePerMonthPrices();

    this.state.totalSubscriptionMonths = 0;
    this.setState({ state: this.state });

  }


  handleSubscriptionSelectionChange(event) {
    const target = event.target;
    var value = target.value;

    console.log(target.value);

    this.state.totalSubscriptionMonths = target.value;
    this.setState({ state: this.state });

  }

  calculatePerMonthPrices(){

    this.state.totalPerMonthPrice = 0;

    //console.log('item');
    this.state.selected.forEach((item) => {
      
       this.state.totalPerMonthPrice += parseInt(this.state.featureList[item].pricePerMonth);
      
    });

    this.setState({ state: this.state });

    return this.state.totalPerMonthPrice;


  }

  submit(e) {
    e.preventDefault()
    console.warn(this.state.selected)
  }

  goToPayment() {

    // create subscription and redirect to payments
    /*
        $subscription->store_id = $inputs['store_id'];
        $subscription->subscription_time_months = $inputs['subscription_time_months'];
        $subscription->subscription_price = $inputs['subscription_price'];
        $subscription->subdomain_website = $inputs['subdomain_website'];
        $subscription->main_domain_website = $inputs['main_domain_website'];
        $subscription->android_mobile_app = $inputs['android_mobile_app'];
    */
   
   let urlPath = window.location.pathname ;
   console.log(urlPath);
   var ar = urlPath.split('/');
   var storeId = ar[1];
   var token = ar[2]; 

   var subscriptionPrice = 0;

   if(this.state.totalSubscriptionMonths == 1){
    subscriptionPrice = parseInt(this.state.totalPerMonthPrice);
   }

   if(this.state.totalSubscriptionMonths == 3){
    subscriptionPrice = parseInt((this.state.totalPerMonthPrice-this.state.totalPerMonthPrice*.1)*3);
   }

   if(this.state.totalSubscriptionMonths == 6){
    subscriptionPrice = parseInt((this.state.totalPerMonthPrice-this.state.totalPerMonthPrice*.2)*6);
   }

   if(this.state.totalSubscriptionMonths == 12){
    subscriptionPrice = parseInt((this.state.totalPerMonthPrice-this.state.totalPerMonthPrice*.3)*12);
   }

    const data = {
      store_id: storeId,
      subscription_time_months: this.state.totalSubscriptionMonths,
      subscription_price: subscriptionPrice,
      subdomain_website: this.state.selected.indexOf("Website on subdomain") === -1 ? 0 : 1,
      android_mobile_app: this.state.selected.indexOf("Android mobile app") === -1 ? 0 : 1,
      main_domain_website: this.state.selected.indexOf("Website on your domain") === -1 ? 0 : 1,  
    };

    axios
      .post(`https://api.intelikart.com/api/subscription`, data, 
      {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
      .then((res) => {
        window.location.href = "https://payments.intelikart.com/subscribe?ik-payment-token="+res.data['ik-payment-token']+"&redirecturl="+window.location.href;
      })
      .catch((err) => {
        console.log(err);
      });

  }


  componentDidMount() {

    let urlPath = window.location.pathname ;
   console.log(urlPath);
   var ar = urlPath.split('/');
   var storeId = ar[1];

    axios
      .get(`https://api.intelikart.com/api/subscription-info?store_id=`+storeId)
      .then((res) => {
        this.setState({
          featureList: res.data.featureList,
          packages: res.data.packages,
          purchaseOptions: res.data.purchaseOptions,
          isOpen: false,
          perMonthPrice: 0,
          subscriptionText: res.data.subscriptionText
        })
      })
      .catch((err) => {
        console.log(err);
      });
  }


  alreadyChecked(item) {

    var index = this.state.selected.indexOf(item.display_name);
    if (index === -1) { 
      this.state.selected.push(item.display_name);
    }

    
  }

  updatePackages(pack) {
    console.log(this.state.packages[pack])
  }


  render() {
    var name = Object.keys(this.state.featureList);
    var data = Object.values(this.state.featureList);

    // console.log(name)
    // console.log(data)

    // console.log(this.state.packages)
    //var packagesname = Object.keys(this.state.packages);

    // var packagesvalue = Object.values(this.state.packages);
    // console.log(packagesname)
    // console.log(packagesvalue)


    return (
      <div>

        <div className="row">
          <div className="col-12 text-center">
            <span style={{ fontWeight: "bold", color: "#34a853" }}> {this.state.subscriptionText} </span><br />
          </div>
        </div>
        <br/>


        <div className="row">
          <div className="col-9 ">
            <span style={{ float: "left", fontWeight: "bold" }}> {"Features"} </span><br />
            <span style={{ float: "left", fontSize: "10px" }}> {"for your store"} </span>

          </div>
          <div className="col-3 ">
            <span style={{ float: "right", fontWeight: "bold" }}> {"Price"} </span> <br/>
            <span style={{ float: "right", fontSize: "10px" }}> {" INR/month"} </span>
          </div>
        </div>
        <hr></hr>

        <form onSubmit={this.handleSubmit}>
          {
            data.map((item, index) => (

              <div className="row" key={index}>
                <div className="col-9 ">
                  {
                    item.mandatory === "0" ? null : this.alreadyChecked(item)
                  }
                  <label style={{ float: "left" }} >
                    <input
                      type="checkbox"
                      checked={item.mandatory === "1" ? true : null}
                      value={item.display_name}
                      onChange={this.handleFeatureSelectionChange}
                    /> {item.display_name}
                  </label>
                </div>

                <div className="col-3 ">
                  <span style={{ float: "right" }}> {item.pricePerMonth} </span>
                </div>

              </div>
            ))
          }
          <hr></hr>
          <div className="row">
                <div className="col-6 ">
                  <span style={{fontWeight: "bold" }}>
                
                Total
                </span>
                <span style={{fontSize: "10px" }}>
                  {" "} (per month)
                 </span>
                 </div>
          <div className="col-6 ">
                  <span style={{ float: "right" }}> INR {this.state.totalPerMonthPrice} </span>
          </div>
                </div>

                <hr></hr>
          <div className="col-md-12 text-center">
            <Button variant={`${this.state.totalPerMonthPrice ? "primary" : "secondary"}`} className="mb-5" onClick={this.openModal}>
              Buy Subscription
          </Button>
          </div>
        </form>


        <Modal show={this.state.isOpen} onHide={this.closeModal}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered>

        <Modal.Header  className="text-center">
          <Modal.Title style={{fontSize: "16px", fontWeight: "bold", color:"#4e4f51" }} >Choose your plan</Modal.Title>
        </Modal.Header>

          <Modal.Body>
            <div class="col-md-12">

              <div className="row">
                <div className="col-9 ">
                  <label style={{ float: "left" }} >
                    <input
                      type="radio"
                      name="months"
                      value="1"
                      group="paymentOption"
                      onChange={this.handleSubscriptionSelectionChange}
                    /> {"1 months"} <br/>
                    <span style={{fontSize: "10px" }}>
                  {" "} ({parseInt(this.state.totalPerMonthPrice)} per month)
                 </span>
                 <br/>
                  </label>
                </div>

                <div className="col-3 ">
                  <span style={{ float: "right" }}> ₹ {parseInt(this.state.totalPerMonthPrice)} </span>
                </div>
              </div>
              <div className="row">
                <div className="col-9 ">
                  <label style={{ float: "left" }} >
                    <input
                      type="radio"
                      name="months"
                      value="3"
                      group="paymentOption"
                      onChange={this.handleSubscriptionSelectionChange}
                    /> {"3 months"} <br/>
                    <span style={{fontSize: "10px" }}>
                  {" "} (<strike>{parseInt(this.state.totalPerMonthPrice)}</strike> {parseInt(this.state.totalPerMonthPrice-this.state.totalPerMonthPrice*.1)} per month) (10% off)
                  </span>
                    <br/>
                  </label>
                </div>

                <div className="col-3 ">
                  <span style={{ float: "right" }}>₹ {parseInt((this.state.totalPerMonthPrice-this.state.totalPerMonthPrice*.1)*3)}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-9 ">
                  <label style={{ float: "left" }} >
                    <input
                      type="radio"
                      name="months"
                      value="6"
                      group="paymentOption"
                      onChange={this.handleSubscriptionSelectionChange}
                    /> {"6 months"}<br/>
                    <span style={{fontSize: "10px" }}>
                  {" "} (<strike>{parseInt(this.state.totalPerMonthPrice)}</strike> {parseInt(this.state.totalPerMonthPrice-this.state.totalPerMonthPrice*.2)} per month) (20% off)
                  </span>
                    <br/>
                  </label>
                </div>

                <div className="col-3 ">
                  <span style={{ float: "right" }}>₹ {parseInt((this.state.totalPerMonthPrice-this.state.totalPerMonthPrice*.2)*6)}</span>
                </div>
              </div>
              <div className="row">
                <div className="col-9 ">
                  <label style={{ float: "left" }} >
                    <input
                      type="radio"
                      name="months"
                      value="12"
                      group="paymentOption"
                      onChange={this.handleSubscriptionSelectionChange}
                    /> {"12 months"}<br/>
                    <span style={{fontSize: "10px" }}>
                  {" "} (<strike>{parseInt(this.state.totalPerMonthPrice)}</strike> {parseInt(this.state.totalPerMonthPrice-this.state.totalPerMonthPrice*.3)} per month) (30% off)
                  </span>
                    <br/>
                  </label>
                </div>

                <div className="col-3 ">
                  <span style={{ float: "right" }}>₹ {parseInt((this.state.totalPerMonthPrice-this.state.totalPerMonthPrice*.3)*12)}</span>
                </div>
              </div>

            </div>


            

          </Modal.Body>
          <Modal.Footer>

          {
                        (this.state.totalPerMonthPrice != 0) ?
                        <Button variant={`${this.state.totalSubscriptionMonths ? "primary" : "secondary"}`} onClick={this.goToPayment}>
              Make Paymentsds
            </Button>
                           
                        : 

                        <Button variant={`${this.state.totalSubscriptionMonths ? "primary" : "secondary"}`}>
              Choose your subscription
            </Button>
          }


            
            

          </Modal.Footer>
        </Modal>



      </div>
    )
  }
}

export default SubscriptionList;
