import * as ActionTypes from "../types";

// Reducers are yet to be implemented .... bass random code likha ha

const initialState = {
  subscriber: [],
  info:[]
};

export default function (state = initialState, action) {
  switch (action.type) {
   

    case ActionTypes.GET_SUBSCRIPTIONS:
      return {
        ...state,
        subscriber: { ...state.subscriber, sub: action.payload },
      };

      case ActionTypes.GET_SUBSCRIPTIONINFO:
      return {
        ...state,
        info:  action.payload ,
      };
    case ActionTypes.POST_SUBSCRIPTIONS:
      var sub = state.subscriber;
      sub.push(action.payload);
      return {
        ...state,
        subscriber: sub,
      };


    default:
      return state;
  }
}
